import React from 'react'
import GramPost from '../GramPost.js'
import Insta from '../Instagram.js'

import './Pages.css';

function Home() {
    const [width, setWidth] = React.useState(window.innerWidth);
    return (
        <div>
            <div style={{height: "525px", width: {width}, overflow: "hidden", position: "relative", textAlign:"center", color: "white"}}>
                <img className="splash" src="globes.jpg" alt="Collection of vintage globes"/>
                <h1 className="title">For the love of all things <font color="#ae4433">Vintage.</font></h1>
                <h2 className="quicklinks">Quickly jump to my content here:</h2>
                <button className="facebook button" type="button" onClick={()=> window.open("http://www.facebook.com/VintagePicksByJen", "_blank")}>Facebook</button>
                <button className="instagram button" type="button" onClick={()=> window.open("http://www.instagram.com/vintagepicksbyjen/", "_blank")}>Instagram</button>
                <button className="pintrest button" type="button" onClick={()=> window.open("https://www.pinterest.com/VintagePicksJen/", "_blank")}>Pinterest</button>
            </div>
            <div>
                <Insta></Insta>
            </div>
        </div>
    )
}

export default Home;