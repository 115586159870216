import React from 'react'

import './Navigations.css';

function MenuBar() {
    return (
        <div>
            <ul className="topnav">
                <li><img src="./chbowl.png" alt="Cathran Home Bowl"/></li>
                <li><p><b>Vintage Picks By Jen</b></p></li>
                <li><a href="/">Home</a></li>
                <li><a href="/AboutMe">About Me</a></li>
            </ul>
        </div>
    )
}

export default MenuBar;