import React from 'react'

import './Navigations.css';

function Footer() {
    return (
        <div>
            <div className="footer">
                <p>(c) 2022</p>
            </div>
        </div>
    )
}

export default Footer;