import React from 'react'

function AboutMe() {
    return (
        <div>
            <p>Under construction!</p>
        </div>
    )
}

export default AboutMe;